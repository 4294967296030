window.addEventListener('heyflow-screen-view', (event) => {
    // console.log('heyflow screen view:', event.detail);
});

window.addEventListener('heyflow-submit', (event) => {
    
    var mailAddress;
    // console.log(event.detail.screenName)
    
    // Get UTM parameters from event.detail.fieldsSimple
    const utm_parameters = {
        utm_source: event.detail.fieldsSimple.utm_source,
        utm_medium: event.detail.fieldsSimple.utm_medium,
        utm_campaign: event.detail.fieldsSimple.utm_campaign,
        utm_content: event.detail.fieldsSimple.utm_content,
        utm_id: event.detail.fieldsSimple.utm_id,
        utm_group: event.detail.fieldsSimple.utm_group,
        utm_term: event.detail.fieldsSimple.utm_term,
        utm_placement: event.detail.fieldsSimple.utm_placement
    };
    
    // Filter out undefined values and empty strings
    const filteredUtmParameters = Object.fromEntries(
        Object.entries(utm_parameters).filter(([key, value]) => value !== undefined && value !== '')
    );
    
    // Create query string from UTM parameters
    const queryString = new URLSearchParams(filteredUtmParameters).toString();
    
    switch(event.detail.screenName) {
        case "benefit-screen":
            mailAddress = event.detail.fieldsSimple.mailAdressBenefit;
            createAccount(mailAddress, utm_parameters);
            break;
        case "consultation-screen":
            mailAddress = event.detail.fieldsSimple.mailAdressConsultation;
            createAccount(mailAddress, utm_parameters);
            break;
        case "magazin-screen":
              mailAddress = event.detail.fieldsSimple.mailAdressMagazin;
              createAccount(mailAddress, utm_parameters);
            break;
        case "ica-screen":
            const baseIcaUrl = 'https://services.deinepflege.de/erstantrag/gast';
            // Append UTM parameters to the URL
            const fullIcaURL = queryString ? baseIcaUrl+'?'+queryString : baseIcaUrl;
            window.location.href = fullIcaURL;
            break;
        case "clu-screen":
            const baseCluURL = 'https://services.deinepflege.de/hoeherstufung/gast';
            // Append UTM parameters to the URL
            const fullCluURL = queryString ? baseCluURL+'?'+queryString : baseCluURL;
            window.location.href = fullCluURL;
            break;
        default:
            break;
    }
    
});

function createAccount(mailAddress, utmParameters) {
    
    const xhr = new XMLHttpRequest();
    xhr.open("POST", 'https://new.api.smart-care-assistant.ucura.com/api/v1/account');
    xhr.setRequestHeader("Content-Type", "application/json; charset=UTF-8")


    const body = JSON.stringify({
      email: mailAddress,
      consents: {"tc": true, "privacy": true},
      utmParams: utmParameters
    });
    
    xhr.onload = () => {
  
      if (xhr.readyState == 4 && xhr.status == 200) {
        // console.log(JSON.parse(xhr.responseText));
        
        const obj = JSON.parse(xhr.responseText);
    
        const date = new Date();
        date.setDate(date.getDate() + 30);
        const session = new Session(obj.accessToken, obj.refreshToken);
        const sessionString = JSON.stringify(session);
        setCookie('session', sessionString, date, '/', '.deinepflege.de', false, 'Strict');
                
                
      } else {
        console.log(`Error: ${xhr.status}`);
      }
    };
    xhr.send(body);
    
}


function setCookie(name, value, expires, path, domain, secure, sameSite) {
  let cookieString = `${encodeURIComponent(name)}=${encodeURIComponent(value)};`;
  if (expires) {
    cookieString += `expires=${expires.toUTCString()};`;
  }
  if (path) {
    cookieString += `path=${path};`;
  }
  if (domain) {
    cookieString += `domain=${domain};`;
  }
  if (secure) {
    cookieString += 'secure;';
  }
  if (sameSite) {
    cookieString += `SameSite=${sameSite};`;
  }
  document.cookie = cookieString;
}

// Session class equivalent in JavaScript
class Session {
  constructor(accessToken, refreshToken) {
    this.accessToken = accessToken;
    this.refreshToken = refreshToken;
  }
}

